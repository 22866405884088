<template>
  <div>
    <b-card no-body class="mb-2">
      <filter-slot
        :filter="filters"
        :filter-principal="filterMain"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="clearFilters()"
        @reload="refreshTable()"
      >
        <template #table>
          <b-table
            ref="approvalList"
            small
            show-empty
            responsive="sm"
            :items="tableProvider"
            :fields="fields"
            sort-direction="desc"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client)="data">
              {{ data.value }} <br />
              <span class="text-warning">{{ data.item.client_account }}</span>
            </template>
            <template #cell(process)="data">
              <b-badge :variant="colorizeProcess(data.value)" pill>{{
                data.value
              }}</b-badge>
            </template>
            <template #cell(balance)="data"> $ {{ data.value }} </template>
            <template #cell(offer_amount)="data"> $ {{ data.value }} </template>
            <template #cell(creditor)="data">
              {{ data.value }} <br />
              <small>{{ data.item.creditor_account }}</small>
            </template>
            <template #cell(created_by)="data">
              {{ data.value }} <br />
              <small>{{ data.item.offer_date | myGlobal }}</small>
            </template>
            <template #cell(tracking)="data">
              <feather-icon
                size="22"
                icon="AlignLeftIcon"
                class="text-primary cursor-pointer"
                @click="openTrackingModal(data.item.id)"
              />
            </template>
            <template #cell(modal)="data">
              <feather-icon
                size="22"
                icon="EyeIcon"
                class="text-warning cursor-pointer"
                @click="openOfferModal(data.item)"
              />
              <br />
              <b-badge
                v-if="[2, 4].includes(data.item.created_from_num)"
                pill
                style="margin-left: 0.4rem"
                variant="light-primary"
              >
                Offer made without balance
              </b-badge>
            </template>

            <template #cell(resumed)="data">
              <feather-icon
                v-if="$route.name == 'settlements-pending'"
                size="22"
                icon="CheckCircleIcon"
                class="text-info cursor-pointer"
                @click="openResumeModal(data.item)"
              />
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>

    <settlement-tracking-modal
      :show="showSettlementTracking"
      :offer="trackingOfferId"
      @closing="showSettlementTracking = false"
    />
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      @reload="refreshTable()"
      @closing="closeSettlementModal"
    />
    <settlement-resume-modal
      :show="showResumeModal"
      :info="resumeModalInfo"
      @reload="refreshTable()"
      @closing="closeResumeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import SettlementTrackingModal from "@/views/debt-solution/views/settlement-offer/SettlementTrackingModal.vue";
import SettlementResumeModal from "./ResumedOfferModal.vue";
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";

export default {
  components: {
    SettlementTrackingModal,
    SettlementOfferModal,
    SettlementResumeModal,
  },
  data() {
    return {
      filters: [],
      totalRows: 0,
      filterMain: {
        type: "input",
        inputType: "text",
        placeholder: "Search Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 1,
      toPage: 2,
      fields: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "creditor",
          label: "Creditor",
        },
        {},
        {
          key: "balance",
          label: "Balance",
        },
        {
          key: "offer_amount",
          label: "Offer Amount",
        },
        {
          key: "created_by",
          label: "Created By",
        },
        {
          key: "tracking",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "View Offer",
          key: "modal",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      trackingOfferId: null,
      showSettlementTracking: false,
      showSettlementOffer: false,
      offerModalInfo: {},
      continueInfo: {
        modal: false,
        mode: "",
        message: "",
        offerId: null,
      },
      showResumeModal: false,
      resumeModalInfo: {},
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    if (this.$route.name == "settlements-rejected") {
      this.fields[2] = {
        key: "process",
        thClass: "text-center",
        tdClass: "text-center",
      };
    }

    if (this.$route.name == "settlements-pending") {
      this.fields.push({
        label: "Offer Resume",
        key: "resumed",
        thClass: "text-center",
        tdClass: "text-center",
      });
    }
  },
  methods: {
    async tableProvider() {
      let status;
      switch (this.$route.name) {
        case "settlements-pending":
          status = 1;
          break;
        case "settlements-completed":
          status = 3;
          break;
        case "settlements-rejected":
          status = 2;
          break;
      }
      const params = {
        name_text: this.filterPrincipal.model,
        // created_from: this.filters[0].model,
        // created_to: this.filters[1].model,
        // approved_from: this.filters[2].model,
        // approved_to: this.filters[3].model,
        // payment_order_from: this.filters[4].model,
        // payment_order_to: this.filters[5].model,
        // payment_date_from: this.filters[6].model,
        // payment_date_to: this.filters[7].model,
        // status: this.filters[8].model,
        // advisor_id: this.filters[9].model,
        // request_type: this.filters[10].model,
        // creditor: this.filters[11].savedValue,

        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        tab: this.$route.meta.tab,
        sortBy: ctx.sortBy,
        sortOrder: ctx.sortDesc == true ? "ASC" : "DESC",
        origin: this.moduleId,
      };
      this.A_COUNT({ user_id: this.isAdvisor ? this.currentUserId : 0 });
      const data = await SettlementsMadeService.getSettlementsMade(params);

      this.totalRows = res.data.total;
      this.toPage = res.data.to;
      this.paginate.perPage = res.data.per_page;
      this.paginate.currentPage = res.data.current_page;

      return res.data.data;
    },
    refreshTable() {
      this.$refs.approvalList.refresh();
    },
    clearFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterMain.model = null;
      this.$refs.approvalList.refresh();
    },
    openTrackingModal(id) {
      this.trackingOfferId = id;
      this.showSettlementTracking = true;
    },
    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.client;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorAccount = item.creditor_account;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = item.balance;
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.account = item.account_id;
      this.offerModalInfo.account_name = item.account;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.showSettlementOffer = true;
    },
    async reviewOffer(accept) {
      this.loading = true;
      const res = await amgApi.post("/debt-solution/update-offer", {
        offer_id: this.continueInfo.offerId,
        user_id: this.currentUser.user_id,
        is_accepted: accept,
        comment: this.continueInfo.message || ".",
      });
      if (res.status != 200) {
        this.$swal.fire({
          icon: "error",
          title: "nel",
        });
        this.handleHidden();
      }
      this.$swal.fire({
        icon: "success",
        title: "Offer Reviewed",
      });
      this.$refs.approvalList.refresh();
    },
    handleContinueHidden() {
      this.continueInfo.message = "";
      this.continueInfo.offerId = null;
      this.$refs.approvalList.refresh(1);
    },
    colorizeProcess(process) {
      switch (process) {
        case "IN CEO":
          return "light-primary";
        case "IN ADVISOR":
          return "light-info";
        case "IN SUPERVISOR":
          return "light-warning";
        default:
          return "light-secondary";
      }
    },
    closeSettlementModal() {
      this.showSettlementOffer = false;
    },
    openResumeModal(item) {
      this.resumeModalInfo = { ...item };
      this.showResumeModal = true;
    },
    closeResumeModal() {
      this.showResumeModal = false;
    },
    refreshTable() {
      this.$refs.approvalList.refresh();
    },
  },
};
</script>
